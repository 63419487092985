.TopNavBar {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
    height: 110px;
    font-size: 1.3em;
    z-index: 999;
    width: 100%;
}

.TopNavBarCard {
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
    height: 70px;
    font-size: 1.3em;
    z-index: 999;
    width: 100%;
}
.TopNavBarTitle {
    justify-content: center;
    align-items: center;
    display: flex;

}

.logoTopNavBar {
    width: 70%;
}