.actualite-box {
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.actualite-box__content {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
}

.actualite-box__line {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.actualite-box__name-date {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.actualite-box__name {
    font-weight: bold;
}

.actualite-box__date {
    color: gray;
}

.actualite-box__actualite {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 10px;
    margin-left: 20px;
}

.actualite-box__text {
    padding: 2px;
}
