.ResetPasswordPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
}

.mainContentResetPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 250px);
}

.ErrorMessage {
  color: red;
  margin-bottom: 20px;
}
.titleResetPassword {
  font-size: 24px;
}