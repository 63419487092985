.content-container {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.content-container p {
    color: #1c1c1b;
    text-align: center;
}

.activate-account-container {
    background-color: #f9ba32;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
}