.my-univers {
    background-color: #0a0a0a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.my-univers__mainBox {
    display: flex;
    color: white;
    justify-content: center;
}

.universVerticalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: white;
}

.universVerticalContent__button {
    background-color: #f5f5f5;
    color: black;
    border: none;
    padding: 20px;
    border-radius: 50%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    font-size: 24px;
}
.myUniversTitle {
    color: white;
    font-size: 40px;
    margin-bottom: 20px;
}