.topCard {
    background-color: #ffb437;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.ZoneAvatarName {
    margin-top: 5px;
    width: 90%;
    display: flex;
    align-items: flex-start;
}

.nameSurname {
    font-size: 1.3em;
    font-weight: bold;
    margin-left: 15px;
}

.listSMA {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    padding-bottom: 4px;
}

.QR-codeZone {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    padding-bottom: 4px;
    flex-direction: column;
}

.qrCode {
    margin-top: 4px;
    width: 35%;
    box-shadow: 0 5px 6px 2px #cccccc;
    border-radius: 10px;
    padding: 10px;
}

.card {
    background-color: #ffffff;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.infoCardZone {
    /*box-shadow: 0 0 10px 3px #cccccc;*/
    margin-bottom: 10px;
    width: 80%;
    text-align: center;
    border-radius: 10px;
    padding: 5px 20px;
    font-size: 1.2em;
}

.ZonePieceJointe {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    padding-bottom: 4px;
    flex-direction: column;
}

.pieceJointe {
    box-shadow: 0 -2px 6px 1px #cccccc;
    margin-bottom: 15px;
    width: 80%;
    text-align: center;
    border-radius: 10px;
}

.containerQrCode {
    padding: 25%;
    text-align: center;
}

.imgLogo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.cardBy {
    width: 90%;
    text-align: right;
    font-size: 15px;
    opacity: 0.7;
    color: grey;
    padding-top: 10px;
}

.QR-codeButton {
    width: 90%;
    text-align: center;
    display: flex;
    justify-content: center;
    gap: 20px;
    color: black;
}

.ZoneLogo {
    width: 100%;
    justify-content: center;
    align-items: center;
}

.iconTopCard {
    background-color: white;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 0 2px 6px 1px #000000;
    display: flex;
    justify-content: center;
    align-items: center;
}