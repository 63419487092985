.inputFormTextCard {
    margin-bottom: 9px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}

.MyCardTopPage {
    background-color: #ffb437;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    padding-bottom: 2%;
    padding-top: 2%;
    /* ombre en bas de la div */

    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
}