.loading-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #1c1c1c;
    position: relative;
}

.loading-screen-logo {
    position: absolute;
    bottom: 50%;
    transform: translateY(-75%);
    max-width: 80%;
}
