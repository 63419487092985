.addSocialMedia {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
    margin-right: 10px;
}

.AddSocialMediaText {
    font-size: 70%;
    margin-bottom: 10px;
}

.mainContentButton {
    display: flex;
    margin-top: 10px;
}