.CellSettings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
    font-size: 20px;
}
.CellSettingsMiddle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    font-size: 20px;

}