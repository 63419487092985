.inputFormText {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.errorForm {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    height: 10px;
    width: 200px;
    padding-bottom: 30px;
}

.signUpForm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.submitButton {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}