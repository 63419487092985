.error404-container {
    text-align: center;
    color: #f9ba32;
    background-color: #426e86;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.error404-title {
    font-size: 95px;
    margin-bottom: 20px;
}

.error404-text {
    font-size: 24px;
    margin-bottom: 40px;
}
