.mainContentSignup {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
}

.mainContentSignup .signupContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mainContentSignup .titleformsignup {
    font-size: 200%;
    margin-bottom: 20px;
    font-family: poppins, serif;
    font-weight: 700;
}

.mainContentSignup .footerForm {
    text-align: center;
}

.logoSignUpPage {
    width: 80%;
}
