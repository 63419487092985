.popup-container {
    text-align: center;
    color: #f9ba32;
    background-color: #61869a;
    padding: 20px;
}

.popup-title {
    font-size: 36px;
    margin-bottom: 20px;
}

.popup-message {
    font-size: 18px;
    margin-bottom: 40px;
}
