.mainContentLogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1c1c1c;
    color: white;
    height: 100vh;
}

.titleformsignup {
    color: #808080;
    font-size: 220%;
    font-weight: 700;
    margin-bottom: 20px;
}

.signupText {
    width: 75%;
}

.imgLogoLogin {
    width: 100%;
    margin-top: 20%;
}