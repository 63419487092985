.navBar {
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1c1c1c;
    color: white;
    height: 70px;
    font-size: 1.1em;
    position: fixed;
    z-index: 999;
    width: 100%;
}