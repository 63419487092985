body {
    margin: 0;
    font-family: poppins, serif;
    height: 100vh;
}

#root {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'morena';
    src: url('./assets/fonts/morena/Morena.otf') format('truetype');
}

@font-face {
    font-family: 'poppins';
    src: url('./assets/fonts/poppins/Poppins-Light.ttf') format('truetype');
}